.haikuContainer {
    text-align: left;
    display: inline-block;
}

@media all and (max-width: 600px) {
    .haikuContainer {
        width: 100% !important;
    }
}

.haikuContainer a {}


.haikuLineInner {
    line-height: .8;
    margin-bottom: 4px;
    display: inline-block;

}

@media all and (max-width: 600px) {
    .haikuLineInner {
        font-size: 14px !important;
    }
}
